
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { useReCaptcha } from 'vue-recaptcha-v3';

import router from '@/router';
import toasts from '@/utils/toasts';
import { Form } from 'vee-validate';

import FormField from '@/components/forms/FormField.vue';

import { Actions, Getters } from '@/store/enums/ForgotPasswordEnums';
import { ForgotPassword } from '@/models/ForgotPasswordModel';
import { ValidEmail } from '@/schemas/common.schemas';

const initialValues = {} as unknown as ForgotPassword;

export default defineComponent({
  name: 'forgot-password-form',
  components: {
    Form,
    FormField,
  },
  data: () => ({
    recaptcha_token: null,
    recaptcha_error: false,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
    formErrors: {},
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.forgotPasswordForm as typeof Form;

    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_FORGOT_PASSWORD_ACTION_ERROR,
    }),

    formSchema() {
      return ValidEmail;
    },
  },
  methods: {
    ...mapActions({
      forgotPassword: Actions.FORGOT_PASSWORD,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
      this.formErrors = {};
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    async submitForgotPasswordForm(values) {
      if (this.loading) return;
      this.loading = true;

      const token = await this.recaptcha();

      if (!token) {
        this.recaptcha_error = true;
        this.loading = false;
        return;
      }

      this.formData = values;
      this.formData.recaptcha_token = token;

      this.forgotPassword(this.formData)
        .then(() => {
          this.loading = false;

          toasts.success(
            'Forgot password link successfully sent to your email',
            () => {
              router.push({ name: 'sign-in' });
            }
          );
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
  setup() {
    const recaptchaInstance = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaInstance?.recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await recaptchaInstance?.executeRecaptcha('login');

      // Do stuff with the received token.
      return token;
    };

    return {
      recaptcha,
    };
  },
});
